/**
  * Name: Binasea | NFT Marketplace ReactJs Template
  * Version: 1.0.1
  * Author: Themesflat
  * Author URI: http://www.themesflat.com
*/
@use "./scss/" as *;
@use "./scss/components/" as *;

@import './assets/font-awesome.css';
@import './assets/binasea.css';


@import url('https://fonts.googleapis.com/css2?family=Bakbak+One&family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/**
  	* Reset Browsers
    * General
	* Elements
  	* Forms
	* Typography
	* Extra classes
	* link style
	* Root
	* Header
	* Site Logo
    * Menu
    * Header Fixed
    * Pagination
    * Scroll Top
    * Footer
    * Widget
    * PreLoad
    * side bar
    * up load
    * heading 
    * hover 
*/

/* Reset Browsers
-------------------------------------------------------------- */

html,
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: inherit;
    font-size: 100%;
    font-style: inherit;
    font-weight: inherit;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

html {
    font-size: 62.5%;
    overflow-y: scroll;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background: var(--background-body);
    color: var(--primary-color);
    font-size: 20px;
    line-height: 30px;
    margin-top: 100px;
}
#root {
    overflow: hidden;
}
.language_container{
    position: absolute;
    width: 150px;
    height: 150px;
    background-color: rgb(33, 150, 231);
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .language_item{
        font-weight: 600;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: 0.3s ease-in-out;
        cursor: pointer;
        &:hover{
            color: white;
            background-color: #1a2130;
            transition: 0.3s ease-in-out;
        }
    }
}
.tf-contact{
    select{
        margin-top: 10px;
        margin-bottom: 3px;
        border: 0px;
        background-color: #1a2130;
        border-radius: 5px;
        padding: 5px 15px;
        height: 35px !important;
        width: 90%;
        outline: none;
        color: #fff;
        margin-left: 5%;
        &:focus{
            border: 1px solid #64e08e;
        }
    }
    input{
        margin-top: 10px;
        margin-bottom: 3px;
       border: 0px;
        background-color: #1a2130;
        border-radius: 5px;
        padding: 5px 15px;
        width: 90%;
        outline: none;
        color: #fff;

        &:focus{
            border: 1px solid #64e08e;
        }
    }

    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
        color: #ffffff;
        opacity: 1; /* Firefox */
    }

    :-ms-input-placeholder { /* Internet Explorer 10-11 */
        color: #ffffff;
    }

    ::-ms-input-placeholder { /* Microsoft Edge */
        color: #ffffff;
    }
}


article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
nav,
section {
    display: block
}

ol,
ul {
    list-style: none
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

blockquote,
q {
    quotes: none
}

a img {
    border: 0
}

img {
    max-width: 100%;
    height: auto;
}

select {
    max-width: 100%
}

.lds-ring {
    display: inline-block;
    position: relative;
    width: 18px;
    height: 18px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 18px;
    height: 18px;
    margin: 4px 10px;
    border: 8px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
/* General
-------------------------------------------------------------- */

body,
button,
input,
select,
textarea {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-rendering: optimizeLegibility;
    overflow-x: hidden;
    overflow-y: auto;
}

img {
    border-radius: 5px;
    height: auto;
    max-width: 100%;
    vertical-align: middle;
    -ms-interpolation-mode: bicubic
}

.text-p,
p {
    font-weight: 400;
}

strong,
b,
cite {
    font-weight: bold;
}

dfn,
cite,
em,
i,
blockquote {
    font-style: italic;
}

abbr,
acronym {
    border-bottom: 1px dotted #e0e0e0;
    cursor: help;
}

mark,
ins {
    text-decoration: none;
}

sup,
sub {
    font-size: 75%;
    height: 0;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

small {
    font-size: 75%;
}

big {
    font-size: 125%;
}

address {
    font-style: italic;
    margin: 0 0 20px;
}

code,
kbd,
tt,
var,
samp,
pre {
    margin: 20px 0;
    padding: 4px 12px;
    background: #f5f5f5;
    border: 1px solid #e0e0e0;
    overflow-x: auto;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    hyphens: none;
    border-radius: 0;
    height: auto;
}

/* Elements
-------------------------------------------------------------- */

tml {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
    -moz-box-sizing: inherit;
    box-sizing: inherit;
}

hr {
    margin-bottom: 20px;
    border: dashed 1px #ccc;
}


/* List */

ul,
ol {
    padding: 0;
}

ul {
    list-style: disc;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

li {
    list-style: none;
}

ul li,
ol li {
    padding: 0.1em 0;
}

dl,
dd {
    margin: 0 0 20px;
}

dt {
    font-weight: bold;
}

del,
.disable {
    text-decoration: line-through;
    filter: alpha(opacity=50);
    opacity: 0.5;
}


/* Table */

table,
th,
td {
    border: 1px solid #343444;
}

table {
    border-collapse: separate;
    border-spacing: 0;
    border-width: 1px 0 0 1px;
    margin: 0 0 30px;
    table-layout: fixed;
    width: 100%;
}

caption,
th,
td {
    font-weight: normal;
    text-align: left;
}

th {
    border-width: 0 1px 1px 0;
    font-weight: bold;
}

td {
    border-width: 0 1px 1px 0;
}

th,
td {
    padding: 8px 12px;
}

/* Media */

embed,
object,
video {
    margin-bottom: 20px;
    max-width: 100%;
    vertical-align: middle;
}

p>embed,
p>iframe,
p>object,
p>video {
    margin-bottom: 0;
}


/* Forms
-------------------------------------------------------------- */
/* Fixes */

button,
input {
    line-height: normal;
}

button,
input,
select,
textarea {
    font-size: 100%;
    line-height: inherit;
    margin: 0;
    vertical-align: baseline;

}

input,
textarea,
select {
    font-size: 16px;
    max-width: 100%;
    background-image: -webkit-linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0));
    /* Removing the inner shadow on iOS inputs */
}

textarea {
    overflow: auto;
    /* Removes default vertical scrollbar in IE6/7/8/9 */
    vertical-align: top;
    height: 150px;
    /* Improves readability and alignment in all browsers */
}

input[type="checkbox"] {
    display: inline;
}

button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    line-height: 1;
    cursor: pointer;
    -webkit-appearance: button;
    border: 0;
}

input[type="checkbox"],
input[type="radio"] {
    padding: 0;
    width: 14px;
    height: 14px;
    margin-right: 11px;
    cursor: pointer;
    vertical-align: sub;
    /* Addresses excess padding in IE8/9 */
}

input[type="search"] {
    -webkit-appearance: textfield;
    /* Addresses appearance set to searchfield in S5, Chrome */
}

input[type="search"]::-webkit-search-decoration {
    /* Corrects inner padding displayed oddly in S5, Chrome on OSX */
    -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}

/* Remove chrome yellow autofill */

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #f7f7f7 inset
}

/* Reset search styling */

input[type="search"] {
    outline: 0
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    display: none;
}

/* Contact Form 7 */

.wpcf7-form select,
.wpcf7-form textarea,
.wpcf7-form input {
    margin-bottom: 0;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -ms-appearance: none;
}

.select {
    position: relative;
    overflow: hidden;
    ::after {
        font-family: 'Font Awesome 5 Pro';
        content: '\f078';
        font-size: 14px;
        right: 10px;
        font-weight: 600;
        color: #1F1F2C;
        display: block;
        position: absolute;
        background: 0 0;
        top: 50%;
        pointer-events: none;
        -webkit-transition: .25s all ease;
        -o-transition: .25s all ease;
        transition: .25s all ease;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    }
}

select option {
    font-size: 16px;
    line-height: 26px;
    color: #1F1F2C;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"] {
    border-radius: 10px;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    line-height: 26px;
    padding: 14px 24px;
    width: 100%;
    background-color: var(--primary-color17);
    color: var(--primary-color2);
}

/* Input focus */
textarea:focus,
input[type="text"]:focus,
input[type="password"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="time"]:focus,
input[type="week"]:focus,
input[type="number"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="search"]:focus,
input[type="tel"]:focus,
input[type="color"]:focus {
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

textarea::placeholder,
input[type="text"]::placeholder,
input[type="password"]::placeholder,
input[type="datetime"]::placeholder,
input[type="datetime-local"]::placeholder,
input[type="date"]::placeholder,
input[type="month"]::placeholder,
input[type="time"]::placeholder,
input[type="week"]::placeholder,
input[type="number"]::placeholder,
input[type="email"]::placeholder,
input[type="url"]::placeholder,
input[type="search"]::placeholder,
input[type="tel"]::placeholder,
input[type="color"]::placeholder {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: var(--primary-color2);
}

/* Button */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    font-family: 'Bakbak One';
    font-size: 16px;
    line-height:24px;
    background-color: #2196e7;
    color: #040B11;
    padding: 17px 50px;
    display: inline-block;
    -webkit-appearance: none;
    @include transition3;
    &:focus,
    &:hover {
        outline: 0;
        background: #2196e7;
        color: #fff;           
    }
}

/* Placeholder color */
::-webkit-input-placeholder {
    color: var(--primary-color2);
}

:-moz-placeholder {
    color: var(--primary-color2);
}

::-moz-placeholder {
    color: var(--primary-color2);
    opacity: 1;
}

.error {
    font-size: 16px;
    color: red;
    margin-bottom: 10px;
    -webkit-transition: all ease 0.3s;
    -moz-transition: all ease 0.3s;
    transition: all ease 0.3s;
}

/* Since FF19 lowers the opacity of the placeholder by default */

:-ms-input-placeholder {
    color: var(--primary-color2);
}

/* Typography
-------------------------------------------------------------- */

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Bakbak One",sans-serif;
    font-weight: 400;
    text-rendering: optimizeLegibility;
}

h1 {
    font-size: 72px;
    line-height: 80px;

    @include mobile {
        font-size: 50px;
        line-height: 60px;
    }
}

h2 {
    font-size: 44px;
    line-height: 56px;

}

h3 {
    font-size: 36px;
    line-height: 44px;

}

h4 {
    font-size: 24px;
    line-height: 30px;
}

h5 {
    font-size: 22px;
    line-height: 30px;
}

h6 {
    font-size: 20px;
    line-height: 28px;
}

/* Extra classes
-------------------------------------------------------------- */
.msg-success {
    background: linear-gradient(to right, #E250E5, #4B50E6, #E250E5);
    background-clip: text;
    -webkit-background-clip: text;
    font-weight: 700;
    background-size: 200% 200%;
    animation: rainbow 2s ease-in-out infinite;
    color: rgb(0 0 0 / 0);
    transition: color .2s ease-in-out;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
}

label.error {
    color: red;
}
.swiper-button-prev,
.swiper-button-next {
    width: 48px;
    height: 48px;
    top: 50%;
    @include mobile {
        display: none;
    }
}

.swiper-button-next {
    right: -68px;
}
.swiper-button-prev {
    left: -46px;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-family: 'Font Awesome 5 Pro';
    font-size: 45px;
    width: 48px;
    height: 48px;
    background-color: var(--primary-color5);
    color:#141B22 ;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    @include transition3;
}

.swiper-button-next:after {
    content: "\f105";
}

.swiper-button-prev:after {
    content: "\f104";
}

.swiper-button-disabled {
    opacity: 1 !important;
    cursor: not-allowed !important;
}
.swiper-button-disabled::after {
    background-color: var(--primary-color15);
    color: var(--primary-color3);
    border:1px solid var(--primary-color12) ;
    cursor: not-allowed !important;
}
.swiper-pagination-bullets {
    position: relative;
    margin-top: 13px;
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    justify-content: center;
    justify-items: center;
    padding-left: 15px;
    .swiper-pagination-bullet {
        margin: 0 11px 0 5px;
        border: 1px solid var(--primary-color5);
        background: transparent;
        width: 8px;
        height: 8px;
        position: relative;
        opacity: 1;
    }
    .swiper-pagination-bullet-active {
        background: var(--primary-color5);
    }
    .swiper-pagination-bullet-active::before {
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
        left: 50%;
        border-radius: 50%;
        content: "";
        width: 16px;
        height: 16px;
        border: 1px solid var(--primary-color5);
        overflow: visible;
    }
}

.tf-container {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    width        : 1440px;
    max-width    : 100%;
}
.tf-container-2 {
    position     : relative;
    margin-left  : auto;
    margin-right : auto;
    padding-right: 15px;
    padding-left : 15px;
    width        : 1800px;
    max-width    : 100%;
}
.tf-container, .tf-container-2 {
    @include tablet {
        width: 100%;
    }
}
.mb60 {
    margin-bottom: 60px !important;
}

.mb87 {
    margin-bottom: 87px;
}

.mb40 {
    margin-bottom: 40px;
}

.mb30 {
    margin-bottom: 40px;
}

.mb28 {
    margin-bottom: 28px;
}
.mb26 {
    margin-bottom: 26px;
}
.mb23 {
    margin-bottom: 23px;
}
.mb22 {
    margin-bottom: 22px;
}
.mb13 {
    margin-bottom: 13px;
}
.mt17 {
    margin-top: 17px;
}
.mt13 {
    margin-top: 13px;
}
.mt10 {
    margin-top: 10px;
}

.mt8 {
    margin-top: 8px;
}

.mt6 {
    margin-top: 6px;
}

.pt10 {
    padding-top: 10px;
}
.visible {
    overflow: visible !important;
}

/* link style
-------------------------------------------------------------- */
a {
    text-decoration: none;
    @include transition3;

    &:hover,
    &:focus {
        color: var(--primary-color5);
        text-decoration: none;
        outline: 0;
        @include transition3;
    }
}

@keyframes rainbow {
    0% {
        background-position: left
    }

    50% {
        background-position: right
    }

    100% {
        background-position: left
    }
}

/* Root
-------------------------------------------------------------- */

:root {
    /* color */
    --background-body: #364850;
    --primary-color: #41454A;
    --primary-color2: #888B8E;
    --primary-color3: #141B22;
    --primary-color4: #5865F2;
    --primary-color5: #2196e7;
    --primary-color6: #F6F6F6;
    --primary-color7: #fff;
    --primary-color8: #fff;
    --primary-color9: #2196e7;
    --primary-color10: #C2C3C5; 
    --primary-color11: #141B22;
    --primary-color12: #fff;
    --primary-color13: #888B8E;
    --primary-color14: #41454A;
    --primary-color15: #fff;
    --primary-color16: #E2E2E3;
    --primary-color17: rgba(194, 195, 197, 0.35);
    --primary-color18: rgba(33, 231, 134, 0.2);
    --primary-color19: #41454A;
    --primary-color20: #434343;
    --primary-color21: #E2E2E3;
    --primary-color22: #E2E2E3;
    --primary-color23: #000000;
    --primary-color24: rgba(194, 195, 197, 0.2);
    --primary-color25:  rgba(194, 195, 197, 0.5);
    --primary-color26: rgba(4, 11, 17, 0.1);
    --primary-color27:#41454A;
    --primary-color28:rgba(67, 67, 67, 0.2);
    --primary-color29:rgba(194, 195, 197, 0.5);
    --primary-color30:rgba(67, 67, 67, 0.4);
    --primary-color31: #888B8E;
    --primary-color32: #41454A;
    --primary-color33: #888B8E;
    --primary-color34: #C2C3C5;
    --primary-color35: rgba(29, 35, 40, 0.2);
    --primary-color36: rgba(28, 36, 51, 0.4);
    


    --product-color16: #dddddd;
    --product-color17: transparent;
    --product-color18: #141B22;
    --product-color19: #fff;
    --product-color20: #fff;
    --product-color21: #040b11ac;
    --product-color22: linear-gradient(356.98deg, #FFFFFF 25.85%, rgba(255, 255, 255, 0) 54.01%);
    --product-color23: #C2C3C5;
    --product-color24: #fff;
    --product-color25: rgba(194, 195, 197, 0.35);

    --bg-section: #f6f6f6;
    --bg-section2: unset;
    --bg-section3: transparent;
    
}

.is_dark {
    --background-body: #364850;
    --primary-color: #C2C3C5;
    --primary-color2: #C2C3C5;
    --primary-color3: #fff;
    --primary-color4: #141B22;
    --primary-color5: #2196e7;
    --primary-color6: #141B22;
    --primary-color7: #364850;
    --primary-color8: #0a4982;
    --primary-color9: #fff;
    --primary-color10: #141B22;
    --primary-color11: #2196e7;
    --primary-color12: #2196e7;
    --primary-color13:  #fff;
    --primary-color14: #fff;
    --primary-color15: transparent;
    --primary-color16: #141B22;
    --primary-color17: rgba(194, 195, 197, 0.15);
    --primary-color18: rgba(33, 231, 134, 0.1);
    --primary-color19: #BBBABE;
    --primary-color20: #fff;
    --primary-color21: rgba(65, 69, 74, 0.4);   
    --primary-color22: rgb(67, 67, 67,0.7);
    --primary-color23: rgba(0, 0, 0, 0.29);
    --primary-color24: rgba(255, 255, 255, 0.05);
    --primary-color25:  rgba(194, 195, 197, 0.15);
    --primary-color26: rgba(194, 195, 197, 0.15);
    --primary-color27:#C2C3C5;
    --primary-color28:rgb(67, 67, 67,0.7);
    --primary-color29:rgba(255, 255, 255, 0.3);
    --primary-color30:#434343;
    --primary-color31: #F7F7F7;
    --primary-color32: #F7F7F7;
    --primary-color33: #41454A;
    --primary-color34: #434343;
    --primary-color35: rgba(29, 35, 40, 1);
    --primary-color36: rgba(255, 255, 255, 0.3);
 
    --product-color16: #585a5b;
    --product-color17: #1F1F2C;
    --product-color18: #C2C3C5;
    --product-color19: #141b2294;
    --product-color20: rgba(255, 255, 255, 0.05);
    --product-color21: #fff;
    --product-color22: linear-gradient(356.98deg, #040B11 25.85%, rgba(4, 11, 17, 0) 54.01%);
    --product-color23: rgba(255, 255, 255, 0.2);
    --product-color24: #040B11;
    --product-color25: #141B22;
    --bg-section: url(./assets/images/background/bs-section.jpg);
    --bg-section2: #040B11;
    --bg-section3: url(./assets/images/background/bs-section.jpg);

}


#page {
    overflow: hidden;
}

/* Page Title  */
.tf-page-title {
    padding: 77px 0;
    text-align: center;
    // background: url('../images/background/bg-page-title.jpg')center center no-repeat fixed;
    background-size: cover;
    &.style-2 {
        padding: 24px 0 15px;
    }
    .breadcrumbs {
        width: 100%;
        @include flex(center,center);
        li {
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            display: inline-block;
            padding: 0 10px 0 0px;
            color: var(--primary-color5);
            &:last-child {
                padding-right: 0;
            }
            a {
                font-weight: 700;
                position: relative;
                color: #888B8E;
                padding-right: 11px;
                &::after {
                    content: "";
                    position: absolute;
                    color: #888B8E;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 2px;
                    height: 16px;
                    background-color:#888B8E ;
                }
                &:hover {
                    color: var(--primary-color5);
                }
            }
            
        }
    }
    .page-title-heading {
        color: #fff ;
        margin-bottom: 12px;
    }
    &.style-2 {
        .breadcrumbs {
            margin-bottom: 0;
        }
    }
}

/* Pagination
-------------------------------------------------------------- */

.tf-pagination {
    margin-top: 10px;
    text-align: center;
    ul {
        @include flex(center,center);
        margin-left: 2px;
        position: relative;

        li {
            display: inline-flex;
            margin-right: 16px;
            width: 48px;
            height: 48px;
            justify-content: center;
            align-items: center;
            border: 1px solid var(--primary-color10);
            @include transition3;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            a {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 18px;
                line-height: 28px;
                color: var(--primary-color13);
            }
            &.continue {
                a {
                    font-size: 32px;
                    margin-top: -11px;

                }
            }
            &.btn-page {
                border-color: var(--primary-color12);
                background-color: var(--primary-color15);
                a {
                    font-size: 34px;
                }
                &.btn-next {
                    background-color: var(--primary-color5);
                    border-color: var(--primary-color5);
                    a {
                        color:#141B22 ;
                    }
                }
            }
            &:hover,
            &.active {
                background-color: var(--primary-color16);
                border-color: var(--primary-color16);
                a {
                    color: var(--primary-color11);
                }
            }
        }
    }
}

/*Action Box -------------------------
------------------------------------------ */
.action-box {
    .action-box-inner {
        background:url('./assets/images/background/bg-action-box1.png'), linear-gradient(268.28deg, #2333DE -1.34%, #0ABE66 100%) ;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 8px;
        text-align: center;
        margin-top: -105px;
        padding: 40px 15px 40px;
        .title {
            color: #fff;
            margin-bottom: 4px;
            margin-left: -16px;
            @include tablet {
                margin-left: 0;
            }
        }
        .content {
            font-size: 18px;
            line-height: 28px;
            color: #F7F7F9;
            margin-bottom: 22px;
            margin-left: -16px;
            @include tablet {
                margin-left: 0;
            }
        }
        .group-btn {
            margin-left: -16px;
            @include tablet {
                margin-left: 0;
            }
            .tf-button {
                padding: 12px 24px;
                margin-right: 20px;
                @include w-402 {
                    margin-right: 0;
                    width: 70%;
                  }
                i {
                    margin-right: 10px;
                }
                &:last-child {
                    margin-right: 0;
                }
                &.discord {
                    background-color: #5865F2;
                    color: #fff;
                    &::before {
                        background-color: #2196e7;
                    }
                }
            }
        }
    }
}
/* PreLoad
-------------------------------------------------------------- */
.preload {
    overflow: hidden;
}

.preload-container {
    position: relative;
    width: 100%;
    height: 100%;
    background: #fff;
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 99999999999;
    display: block;
    overflow: hidden;
}

.preload-logo {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 400px;
    height: 400px;
    z-index: 100;
    margin: -200px 0 0 -200px;
    background: #ffffff;
    // background: url(../icon/preload.png) center center no-repeat;
    animation:
        preload 1s linear infinite alternate;
    -webkit-animation-name: bounceIn;
    animation-name: bounceIn;
}


@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}


.tf-blog {
    padding: 100px 0 205px;
    .tf-blog-item {
        box-sizing: border-box;
        padding: 30px 20px 28px;
        background: var(--primary-color8);
        margin-bottom: 30px;
        .image {
            margin-bottom: 26px;
            position: relative;
            img {
                width: 100%;
            }
            .category {
                position: absolute;
                top: 14px;
                right: 14px;
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 14px;
                line-height: 22px;
                color: #fff;
                padding: 8px 10px;
                background-color: var(--primary-color5);
            }
        }
        .title {
            margin-bottom: 4px;
            a {
                color: var(--primary-color3);
                &:hover {
                    color: var(--primary-color5);
                }
            }
        }
        .content {
            margin-bottom: 15px;
        }
        .meta {
            margin-bottom: 2px;
            &> span {
                margin-right: 0px;
                padding-right: 8px;
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
                color: #888B8E;
                @include flex(center,left);
                display: inline-flex;
                position: relative;
                margin-right: 8px;
                svg {
                    margin-right: 7px;
                    path {
                        fill: var(--primary-color5);
                    }
                }
                &::after {
                    content: '';
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    right: 0;
                    width: 1px;
                    height: 16px;
                    background-color:#888B8E ;
                }
                &:last-child {
                    &::after {
                        display: none;
                    }
                }
            }
            .admin {
                i {
                    font-size: 15px;
                    margin-left: 2px;
                }
            }
        }
       .btn-readmore {
            display: inline-flex;
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            color: var(--primary-color9);
            margin-left: 9px; 
            i {
                margin-left: 4px;
                font-size: 23px;
            }
            &:hover {
                padding-left: 10px;
                color: var(--primary-color5);
            }
       }
        &.style-2 {
            padding: 24px 24px 30px 24px;
            .title {
                margin-bottom: 18px;
            }
            .content {
                font-size: 16px;
                line-height: 24px;
                margin-bottom: 15px;
            }
        }
    }
    .main-content {
        margin-right: 50px;
    }

}


.tf-blog-detail {
    padding: 28px 0 102px 0;
}
.detail-inner {
    margin-right: 30px;
    @include tablet {
        margin-right: 0;
    }
    .image {
        margin-bottom: 31px;
        &.style-2 {
            margin-right: -25px;
            img.mr20 {
                margin-right: 25px;
            }
            img {
                width: calc(50% - 30px);
            }
        }
    }
    .title {
        margin-bottom: 39px;
        @include flex(center,space-between);
        h3 {
            color: var(--primary-color3);
        }
        .category {
            color: var(--primary-color3);
            background-color: var(--primary-color5);
            padding: 8px 10px;
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
        }
    }
    .meta {
        margin-bottom: 15px;
        span {
            display: inline-flex;
            align-items: center;
            color: #888B8E;
            margin-right: 6px;
            padding-right: 13px;
            position: relative;
            svg {
                margin-right: 7px;
            }
            &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 0;
                width: 1px;
                height: 20px;
                background-color: #888B8E;
            }
            &:last-child {
                margin-right: 0;
                padding-right: 0;
                &::after {
                    display: none;
                }
            }
        }
    }
    .content-inner {
        margin-bottom: 21px;
        &.mb24 {
            margin-bottom: 24px;
        }
        .title {
            margin-bottom: 11px;
            color: var(--primary-color3);
            font-size: 30px;
            line-height: 42px;
        }
    }
    .post-infor {
        padding: 27px 33px 23px;
        margin-bottom: 40px;
        background-color: var(--primary-color18);
        border-left: 8px solid var(--primary-color5);
        .title {
            font-weight: 600;
            font-size: 24px;
            line-height: 30px;
            margin-bottom: 10px;
        }
        .content {
            @include flex(center,left);
            .star {
                margin-right: 9px;
                i {
                    color: #E8D41F;
                    font-size: 17px;
                    margin-right: 6px;
                }
                span {
                    color: var(--primary-color19);
                    font-size: 14px;
                    line-height: 22px;
                }
            }
            .name {
                color: var(--primary-color20);
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 16px;
                line-height: 24px;
            }
        }
    }
    .content-bottom {
        @include flex(center,space-between) ;
        align-items: center;
        margin-bottom: 34px;
        .widget {
            @include flex(center,space-between) ;
            .widget-title {
                font-size: 16px;
                line-height: 24px;
                margin-right: 14px;
                margin-top: 4px;
                color: var(--primary-color14);
            }
            ul{
                @include flexitem;
                li {
                    margin-right: 12px;
                    a {
                        font-size: 18px;
                        color: var(--primary-color13);
                        &:hover {
                            color: var(--primary-color5);
                        }
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                   
                }
            }
            &.widget-socical {
                ul{
                    li:nth-child(1) {
                        a {
                           font-size: 15px;
                        }
                    }
                }
            }
            &.widget-tag {

                ul{
                    li {
                        margin-right: 0px;
                        a {
                            color: var(--primary-color14);
                            padding: 8px 12px;
                            background-color: var(--primary-color21);
                            font-size: 12px;
                            line-height: 18px;
                            margin-right: 11px;
                            color: var(--primary-color20);
                            &:hover {
                               
                                color: #fff;
                                background-color: var(--primary-color5);
                            }
                        }
                        &:last-child {
                            a {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }

        @include mobile {
            flex-wrap: wrap;

            .widget.widget-tag  {
                margin-bottom: 10px
            }
        }
        
    }
    .post-navigator {
        @include flex(center,space-between);
        margin-bottom: 59px;
        li {
            max-width: 40%;
            background-color: var(--primary-color8);
            padding: 21px 20px;
            @include flex(center,left);
            .thump {
                margin-right: 16px;
                flex-shrink: 0;
            }
            .content {
                margin-top: -11px;
                .btn-post {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 22px;
                    color: #888B8E;
                    &:hover {
                        color: var(--primary-color5);
                    }
                }
                .title {
                    margin-bottom: 0;
                    margin-top: 2px;
                    a {
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        color: var(--primary-color3);
                        &:hover {
                            color: var(--primary-color5);
                        }
                    }
                }
            }
            &:last-child {
                flex-direction: row-reverse;
                .thump {
                    margin-right: 0;
                    margin-left: 16px;
                }
                .content {
                    text-align: right;
                }
            }
        }

        @include mobile {
            flex-wrap: wrap;
            li {
                max-width: 100%;
                width: 100%;
                margin-bottom: 30px;
            }
        }
    }
    #comments {
        .heading {
            margin-bottom: 5px;
            color: var(--primary-color3);
        }
        .sub-heading {
            margin-bottom: 30px;
        }
        .comment-form {
            fieldset {
                float: left;
                width: 34%;
                margin-bottom: 20px;
                padding-right: 10px;
                padding-left: 0;
                &.message {
                    width: 100%;
                    margin-bottom: 23px;
                    padding-right: 0;
                }
                &:nth-child(2n) {
                    width: 66%;
                    padding-left: 10px;
                    padding-right: 0;
                }
            }
            .tf-button {
                background-color: var(--primary-color5);
                color: #fff;
            }
        }
    }
}

.side-bar {
    .widget {
        padding: 0;
        background-color: var(--primary-color8);
        border: none;
        margin-bottom: 40px;
        &:last-child {
            margin-bottom: 0;
        }
        .widget-title {
            padding: 16px 24px;
            color: var(--primary-color3);
            border-bottom: 2px solid var(--primary-color22);
        }
        ul {
            padding: 4px 24px 18px;
        }
        &.widget-search {
            margin-bottom: 21px;
            form {
                position: relative;
            }
            input {
                padding: 13px 24px 9px;
                color: #888B8E;
                font-size: 16px;
                line-height: 26px;
                background-color: var(--primary-color8);
                &::placeholder {
                    color: #888B8E;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
            .btn-search {
                position: absolute;
                right: 19px;
                top: 52%;
                transform: translateY(-50%);
            }
        }
        &.widget-category {
            ul {
                li {
                    padding-bottom: 0;
                    a {
                        font-size: 14px;
                        line-height: 24px;
                    }
                }
            }
        }
        &.widget-recent-post {
            ul {
                padding-top: 19px;
            }
            li {
                @include flex(center,left);
                margin-bottom: 9px;
                &:last-child {
                    margin-bottom: 0;
                }
                .post-img {
                    flex-shrink: 0;
                    margin-right: 16px;
                }
                .post-content {
                    width: 86%;
                    .title {
                        font-family: 'Poppins';
                        font-weight: 700;
                        font-size: 18px;
                        line-height: 28px;
                        margin-bottom: 3px;
                    }
                    .post-meta {
                        @include flex(center,left);
                        span {
                            font-size: 12px;
                            line-height: 18px;
                            color: var(--primary-color5);
                            position: relative;
                            padding-right: 8px;
                            margin-right: 6px;
                            &::after {
                                content: "";
                                position: absolute;
                                top: 50%;
                                transform: translateY(-50%);
                                right: 0;
                                width: 1px;
                                height: 12px;
                                background-color: #888B8E;
                            }
                            &:last-child {
                                padding-right: 0;
                                margin-right: 0;
                                &::after {
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.widget-tag {
           ul {
            padding-top: 18px;
            padding-bottom: 11px;
           }
            ul li {
                display: inline-block;
                margin: 0 3px 11px 0;
                a {
                    font-size: 12px;
                    line-height: 18px;
                    font-weight: 400;
                    padding: 8px 12px;
                    background-color: var(--primary-color21);
                    color: var(--primary-color14);
                    &:hover {
                        color: #fff;
                        background-color: var(--primary-color5);
                    }
                }
                
            }
        }
    }
}

.logo-slider-wrap {
    white-space: nowrap;
    overflow: hidden;
    background-color: #2196e7;
    padding: 9px 80px;
    &:hover {
        .logo-slider-inner  {
            cursor: pointer;
            animation-play-state: paused;
        }
    }
}

.logo-slider-inner  {
    display: inline-block;
    padding-left: 0%; 
    animation: logoslider 80s linear infinite;
    h3 {
        display: inline-block;
        color: #141B22;
        margin-right: 113px;
        font-size: 32px;
        line-height: 42px;
    }
    &.style-2 {
        animation: logoslider2 80s linear infinite;
        }
}

@keyframes logoslider {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes logoslider2 {
    from {
        transform: translateX(0%);
    }
    to {
        transform: translateX(-100%);
    }
}

.tf-category {
    padding: 0 0 81px 0;
    .tf-heading {
        padding-left: 6px;
    }
}


.home-1 {
    .tf-section {
        padding: 0;
    }
    .tf-about {
        padding: 59px 0 71px;
        position: relative;
        .icon,.icon-2 {
            position: absolute;
            top: -14%;
            left: 5%;
        }
        .icon {
            top: 24%;
            left: auto;
            right: 12%;
        }
    }
    .tf-collection {
        padding-bottom:70px;
    }

    .section-work {
        padding: 100px 0 51px;
    }
    .team {
        padding-bottom: 70px;
    }
    .tf-faq {
        padding-bottom: 76px;
    }

    .tf-partner-sec {
        padding-bottom: 173px;
        position: relative;
        .icon {
            position: absolute;
            bottom: 25%;
            left: 0%;
        }
    }
}

.tf-partner-sec {
    padding-bottom: 173px;
    position: relative;
    .icon {
        position: absolute;
        bottom: 25%;
        left: 0%;
    }
}

.home-2 {

    .tf-section {
        padding: 0;
    }
    .section-work {
        padding: 60px 0 62px;
    }

    .team,
    .tf-faq ,
    .tf-about {
        padding-bottom: 69px;
    }

    .tf-collection {
        padding-bottom: 70px;
    }
    .section-roadmap2 {
        padding-bottom: 159px;
        position: relative;
    }
    .tf-roadmap {
        .box-right {
            .roadmap-box:last-child {
                margin-bottom: 0;
            }
        }
    }
    .tf-about {
        position: relative;
        .icon,.icon-2 {
            position: absolute;
            top: -8%;
            left: 16%;
        }
        .icon {
            top: 63%;
            left: auto;
            right: 2%;
        }
    }

}

.home-3 {
    .tf-section {
        padding: 0;
    }
}



.tf-faq {
    padding: 100px 0 180px;

    @include mobile {
        padding: 70px 0 180px !important;
    }
}

.tf-testimonial-page {
    padding: 100px 0 176px;
    .tf-heading {
        margin-bottom: 80px;
        max-width: 800px;
        .heading {
            margin-bottom: 15px;
        }
    }
}

.team-page {
    .tf-team-detail {
        padding: 100px 0;
    }
    
    .team {
        padding-bottom: 68px;
        position: relative;
        .icon {
            position: absolute;
            top: 34%;
            left: 0%;
        }
    }

    .tf-partner-sec {
        padding-bottom: 174px;
        position: relative;
        .icon {
            position: absolute;
            bottom: 25%;
            left: 0%;
        }
    }
}

.tf-about {
    padding: 100px 0 70px;
    position: relative;
        .icon,.icon-2 {
            position: absolute;
            top: -14%;
            left: 5%;
        }
        .icon {
            top: 24%;
            left: auto;
            right: 12%;
        }
    .tf-heading {
        margin-bottom: 40px;
        .heading {
            margin-bottom: 16px;
        }
    }
    .counter-wrap {
        @include flex(center,center);
        margin-bottom: 60px;
        .tf-counter {
            text-align: center;
            margin-right: 60px;
            &:last-child {
                margin-right: 0;
            }
            h6 {
                margin-bottom: 8px;
            }
        }
    }
    &.style-2 {
        position: relative;
        .icon,.icon-2 {
            position: absolute;
            top: 7%;
            left: 16%;
        }
        .icon {
            top: 68%;
            left: auto;
            right: 3%;
        }
        .tf-heading {
            margin-top: 64px;
            margin-left: 3px;
            padding-right: 46px;
            margin-bottom: 30px;
        }
        .counter-wrap {
            justify-content: left;
            margin-bottom: 40px;
        }
        .tf-button {
            padding: 10px 22px;

            @include tablet {
                margin-bottom: 50px;
            }
        }
        
    }
}

.swiper-button-next.button-team-next,
.swiper-button-prev.button-team-prev {
    top: 45%;
}
.swiper-button-prev.button-team-prev {
    left: -67px;
}

.w-100 {
    @include mobile {
        width: 100% !important;
    }
}

.home-2 section.tf-work-ss {
    padding: 66px 0px 62px;
}

.home-3 .tf-roadmap-ss {
    padding: 66px 0 108px;
}

.home-3 .tf-team-ss {
    padding-bottom: 68px;
}

.home-3 .tf-partner-sec {
    padding-bottom: 68px;
}

.home-3 .tf-faq {
    padding-bottom: 60px;
}
.page-roadmap .tf-roadmap-ss {
    padding: 66px 0 180px;
}

.sidebar-collection {
    .accordion-item {
        padding: 0;
       

        .accordion-header .accordion-button {
            font-family: "Bakbak One";
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: var(--primary-color3);
            padding: 16px 12px;
            &::after {
                content: "\f068";
                font-family: "Font Awesome 5 Pro";
                position: absolute;
                right: 16px;
                top: 50%;
                transform: translateY(-50%);
                font-size: 20px;
                color: #2196e7;
                -webkit-transition: all 0.3s ease;
                -moz-transition: all 0.3s ease;
                -ms-transition: all 0.3s ease;
                -o-transition: all 0.3s ease;
                transition: all 0.3s ease;
                opacity: 1;
            }

            &.collapsed {
                &::after {
                    content: "\f067";
                }
            }
        }
    }
}

.tf-collection-inner {
    padding: 100px 0 175px;
}
.sidebar.sidebar-collection {
    padding-right: 50px;
    .widget {
        background-color: var(--primary-color8);
        margin-bottom: 11.5px;
        .widget-title {
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: var(--primary-color3);
            padding: 16px 12px;
            &.active {
                border-bottom: 2px solid var(--primary-color22);
            }
        }
        .widget-content {
            padding: 20px 23px ;
    
        }
        &.widget-accordion {
            .widget-title {
                cursor: pointer;
                position: relative;
                &::after {
                    content: '\f067';
                    font-family: 'Font Awesome 5 Pro';
                    position: absolute;
                    right: 16px;
                    top: 50%;
                    transform: translateY(-50%);
                    font-size: 20px;
                    color: #2196e7;
                    @include transition3;
                }
                &.active {
                    &::after {
                        content: '\f068';
                    }
                }
            }
            .checkbox-item {
                @include flex(center,left);
                cursor: pointer;
                margin-bottom: 6px;
                span {
                    color: var(--primary-color2);
                    font-size: 14px;
                    line-height: 22px;
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .custom-checkbox {
                position: relative;
                display: inline-block;
                margin-right: 7px;
            }
            input {
                position: absolute;
                opacity: 0;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 1;
                margin-right: 0px;
            }
            .btn-checkbox {
                width: 16px;
                height: 16px;
                display: flex;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                align-items: center;
                justify-content: center;
                background-color: transparent;
                border: 1px solid var(--primary-color2);
                border-radius: 2px;
                &::after {
                    content: "\e921";
                    font-family: 'binasea';
                    font-size: 10px;
                    left: 2px;
                    top: 0px;
                    color: #fff;
                    display: none;
                }
                
                
            }
            input:checked~.btn-checkbox {
                background-color: var(--primary-color5);
                border: 1px solid var(--primary-color5);
                &::after {
                    display: flex;
                }
            }
        }

    }
    
    
}

.widget-search {
    form {
        position: relative;

        min-width: 280px;
        input {
            padding: 13px 25px 8px 23px;
            font-size: 16px;
            line-height: 24px;
            color: #888B8E;
            &::placeholder {
                font-size: 16px;
                line-height: 24px;
                color: #888B8E;
            }
        }
    }
    
    .btn-search {
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
        color: #888B8E;
    }
}

.tf-collection-inner {
    .top-option {
        margin-bottom: 20px;
        @include flex(center,space-between);
        .heading {
            color: var(--primary-color3);
        }
    }
    .filter-content {
        margin-bottom: 39px;
        @include flex(center,left);
        background-color: var(--primary-color8);
        padding: 12px 20px;
        li {
           a {  
                display: inline-block;
                padding: 7px 20px;
                border: 1px solid var(--primary-color33);
                color: var(--primary-color31);
                font-size: 16px;
                line-height: 26px;
                margin-right: 11px;
                i {
                    margin-left: 12px;
                    color: var(--primary-color32);
                }
                
           }
        //    &:last-child {
        //         a {
        //             margin-right: 0;
        //             border: none;
        //             color: #2196e7;
        //             font-family: 'Bakbak One';
        //             font-weight: 400;
        //             font-size: 14px;
        //             line-height: 22px;
        //             padding: 0;
        //         }
        // }
        }
    }
   
}

.page-collection {
    .tf-collection-ss {
        padding: 0 0 170px;
    }
}


/* button 
--------------------------------------------------*/
.tf-button {
    display      : inline-block;
    font-family: 'Bakbak One';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color        : #141B22;
    background-color: var(--primary-color5);
    padding      : 18px 36px;
    border: 2px solid transparent;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @include transition3;
    i {
        margin-right: 8px;
    }
    
    &.active {
        background-color: #5865F2;
        color: #fff;
    }
    &.style-2 {
        background: transparent;
        color: var(--primary-color3);
        border: 2px solid #2196e7;
        
    }
    &::before {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0px;
        z-index: -1;
        height: 110%;
        width: 0%;
        content: "";
        background-color: #2196e7;
        @include transition3;
    }
    &:hover {
        z-index: 2;
        color: #fff;
        background: transparent;
        border: 2px solid #2196e7;
        &::before {
            width: 110%;
            background-color: transparent;
        }
        &.style-2 {
            background-color: #2196e7;
            color: var(--primary-color3);
            &::before {
                background-color: #2196e7;
            }
        }
        
    }

}

/* Heading
--------------------------------------------------*/
.tf-heading {
    text-align: center;
    max-width: 930px;
    margin: 0 auto;
    z-index: 1;
    position: relative;
    .heading {
        color: var(--primary-color3);
        span {
            color: var(--primary-color5);
        }
    }
    .sub-heading {
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color);
    }
    &.style-2 {
        text-align: left;
        max-width: unset;
    }
   
}
/* Counter
--------------------------------------------------*/
.tf-counter {
    h6 {
        color: var(--primary-color);
    }
    .content {
        font-family: 'Bakbak One';
        font-weight: 400;
        font-size: 56px;
        line-height: 68px;
        color: var(--primary-color9);
        text-shadow: 0px 4px 16px rgba(255, 255, 255, 0.4);
    }
}





/* partner
--------------------------------------------------*/
.tf-partner {
    text-align: center;
    padding: 20px 0;
    img {
        filter: brightness(0);
        -webkit-filter: brightness(0);
    }
}

.is_dark .tf-partner {
    img {
        filter: brightness(0) invert(1);
        -webkit-filter: brightness(0) invert(1);
    }
}

.partner-2,
.partner {
    margin-bottom: 30px;
    .swiper-slide:nth-child(2n) {
        .tf-partner {
            background-color: var(--primary-color8); 
        }
    }
 
    &.style-2 {
        .swiper-slide:nth-child(n) {
            .tf-partner {
                background-color: var(--primary-color8); 
            }
        }
        .swiper-slide:nth-child(2n) {
            .tf-partner {
                background-color: transparent; 
            }
        }
    }
}

/* popup connect wallet
--------------------------------------------------*/
.modal-open {
    padding-right: 0 !important;
}
.modal {
    background: rgba(4, 11, 17, 0.7);
    overflow: hidden;
    width: 100vw;
    backdrop-filter: blur(10px);
    z-index: 99999;
    & .modal-dialog {
        max-width: 629px;
        overflow: hidden;
        margin: 150px auto;
       & .modal-content {
            background: var(--primary-color6);
            border: unset;
            text-align: center;
            & .modal-body {
                padding: 42px 60px;
                position: relative;
                .btn-close {
                    position: absolute;
                    top: 17px;
                    right: 21px;
                    color: var(--primary-color20);
                    font-size: 28px;
                }
                & h3 {
                    color: var(--primary-color3);
                    text-align: center;
                    margin-bottom: 10px;
                }
                & .sub-heading {
                    font-size: 18px;
                    line-height: 28px;
                    color: var(--primary-color);
                    margin-bottom: 41px;
                    padding: 0 33px;
                }
                .bottom {
                    font-size: 16px;
                    line-height: 26px;
                    padding: 0 30px;
                    color: var(--primary-color13);
                    a {
                        color: var(--primary-color5);
                    }
                }
                .tf-wallet {
                    position: relative;
                    background-color: var(--primary-color24);
                    @include flex(center,left);
                    padding: 23px 27px 19px 33px;
                    margin-bottom: 18px;
                    .image {
                        margin-right: 20px;
                    }
                    .content {
                        text-align: left;
                        .title {
                            font-weight: 500;
                            font-size: 22px;
                            line-height: 120%;
                            color: var(--primary-color3);
                            margin-bottom: 2px;
                        }
                        .sub {
                            color: #888B8E;
                            font-size: 16px;
                            line-height: 26px;
                        }
                    }
                    &.mb30 {
                        margin-bottom: 30px;
                    }
                    &::after {
                        content: '\f054';
                        font-family: 'Font Awesome 5 Pro';
                        position: absolute;
                        top: 50%;
                        right: 27px;
                        transform: translateY(-50%);
                        color: #C2C3C5;
                    }
                }
            }
        }
    }
}


/* Contact 
--------------------------------------------------*/

.create-inputs{
    input{
        border-radius: 10px;
        margin-bottom: 25px;
    }
}
.tf-infor-wrap {
    @include flexitem;
    flex-wrap: wrap;
    margin-left: -30px;
    margin-bottom: 40px;
    .tf-infor {
        width: calc(33.33% - 30px);
        margin-left: 30px;
        text-align: center;
        border: 1px solid var(--primary-color30);
        background-color: var(--primary-color15);
        padding: 40px 0px ;
        position: relative;

        @include mobile {
            width: 100%;
        }
        .icon {
            margin-bottom: 20px;
        }
        .title {
            color: var(--primary-color3);
            margin-bottom: 6px;
        }
        .sub-title {
            font-size: 18px;
            line-height: 28px;
            color: var(--primary-color2);
        }
    }
}
.tf-contact {
    padding: 100px 0 204px;
    .tf-heading {
        margin-bottom: 59px;
        .heading {
            margin-bottom: 6px;
        }
        .sub-heading {
            font-size: 20px;
            line-height: 30px;
            color: var(--primary-color2);
        }
    }
    
    .comment-form {
        .form-inner {
            @include flexitem;
            flex-wrap: wrap;
            margin-bottom: 20px;
        }
        fieldset {
            margin-bottom: 20px;
            width: 100%;
            &:first-child {
                width: 35%;
                margin-right: 20px;
            }
            &:nth-child(2) {
                width: calc(65% - 20px);
            }
        }
        
         .form-select {
            position: relative;
            i {
                position: absolute;
                top: 20px;
                right: 23px;
                color: var(--primary-color8);
                font-size: 10px;
            }
         }

         .btn-submit {
            text-align: center;
            button {
                padding: 10px 34px;
                color: var(--primary-color3);
            }
         }
    }
}

/* Live Auction 
--------------------------------------------------*/

.filter-menu {
    @include flex(center,left);
    li {
        padding: 7.5px 16px;
        margin-right: 14.5px;
        border-radius: 100px;
        border: 1px solid var(--primary-color36);
        background-color: var(--primary-color22);
        color: #565660;
        @include transition3;
        cursor: pointer;
        a {
            color: #565660;
            font-weight: 700;
            font-size: 14px;
            line-height: 22px;
            letter-spacing: 0.1em;
        }
        &.active,&:hover {
            background-color: #3749E9;
            border-color: #3749E9;
            a {
                color: #fff;
            }
        }
    }
    &.style-2 {
        justify-content: center;
        li {
            border-radius: 12px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

// dropdown
.dropdown>a {
    position             : relative;
    display              : inline-block;
    padding              : 10px 20px;
    min-width            : 180px;
    border-radius        : 100px;
    -moz-border-radius   : 100px;
    -webkit-border-radius: 100px;
    background           : var(--primary-color6);
    font-size            : 14px;
    font-weight          : 700;
    line-height          : 22px;
    width: 180px;
    border: 1px solid var(--primary-color36);
    color                : var(--primary-color5);
    white-space: nowrap; 
    text-overflow: ellipsis; 
    overflow: hidden;
    @include transition3;
    z-index              : 10;
    &::after {
        font-family      : 'Font Awesome 5 Pro';
        font-size        : 12px;
        font-weight      : 400;
        content          : "\f078";
        position         : absolute;
        color            : var(--primary-color5);
        right            : 21px;
        top              : 50%;
        @include transitionY;
    }
}

.dropdown>a:focus,
.dropdown>a:hover {
    color: var(--primary-color5);
}

.dropdown ul.show {
    opacity           : 1;
    @include transitionY0;
    @include transition3;
}

.dropdown li {
    background        : var(--primary-color6);
    width             : 100%;
    padding           : 9px 10px 8px 16px;
    list-style        : none;
    display           : block;
    margin            : 0;
    @include transition3;
    
}


.dropdown ul,
.dropdown li.active,
.dropdown ul,
.dropdown li:hover {
    background: var(--primary-color6);
}

.dropdown ul {
    z-index           : 10;
    position          : absolute;
    cursor            : pointer;
    width             : 100%;
    height            : auto;
    background: var(--primary-color6);
    border:1px solid  var(--primary-color36);
    z-index           : 1;
    @include transitionY3;
    opacity           : 0;
    box-shadow: 0px 8px 29px 1px rgba(0, 0, 0, 0.15);
    border-radius: 16px;
    overflow: hidden;
    margin-top: 10px;
    position: absolute;
    top: 100%;
}
.dropdown::after {
    content: '';
    width: 100%;
    bottom: -20px;
    left: 0;
    position: absolute;
    height: 100%;
}
.dropdown ul,
.dropdown li span {
    @include transition3;
    font-size         : 14px;
    line-height       : 22px;
    color             : var(--primary-color5);
    font-weight       : 400;
}

.top-menu {
    @include flex(center,space-between);
    margin-bottom: 39px;
    .dropdown {
        @include flex(center,left);
    }
}


/* Item Details 
--------------------------------------------------*/
.tf-item-detail {
    padding: 100px 0 ;
}

.tf-collection {
    padding-bottom: 253px;
}

.tf-item-detail-image {
    position: relative;
    .countdown-inner {
        background: rgba(255, 255, 255, 0.3);
        backdrop-filter: blur(13px);
        text-align: center;
        min-width: 608px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 20px;
        padding: 18px 0 27px;
        .heading {
            color: #fff;
            margin-bottom: 12px;
        }
    }
}
.tf-item-detail-inner {
    margin-left: 70px;
    margin-top: 13px;

    @include tablet {
        margin-left: 0
    }
    .title {
        color: var(--primary-color3);
        margin-bottom: 25px;
    }
    .des {
        color: var(--primary-color2);
        margin-bottom: 20px;
    }
    .infor-item-wrap {
        margin-left: -16px;
        margin-bottom: 8px;
        @include flexitem;
        flex-wrap: wrap;
        .active{
            background-color: yellow;
        }
        .infor-item-box {
            border-radius: 10px;
            cursor: pointer;
            margin-left: 16px;
            width: calc(33.33% - 16px);
            background-color: var(--primary-color8);
            margin-bottom: 23px;
            padding: 20px;
            transition: 0.3s ease-in-out;
            &:hover {
                transition: 0.3s ease-in-out;
                transform: scale(1.05);
            }
            @include mobile {
                width: calc(50% - 16px);
            }
            .category {
                font-size: 16px;
                line-height: 26px;
                color: var(--primary-color2);
                margin-bottom: 4px;
            }
            .name {
                color: var(--primary-color14);
            }
        }
    }
    .input-wrapper{
        border-radius: 10px;
        position: relative;
        padding: 15px 5px 5px 5px;
        background-color: rgba(0, 17, 27, 0.41);
        margin-bottom: 20px;
    }
    .infor-item-box.active{
        background-color: #3eb5ca;
        .category{
            color: black;
        }
        .name{
            color: black;
        }
    }
    .price {
        font-family: 'Bakbak One';
        font-weight: 400;
        font-size: 36px;
        line-height: 44px;
        color: #2196e7;
        margin-bottom: 39px;
        .heading {
            color: var(--primary-color3);
        }
    }
    .check-terms{
        text-align-last: center;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
    }

    .group-btn {
        .tf-button.opensea {
            width: 100%;
            padding: 16px 0;
            text-align: center;
            margin-bottom: 24px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            i {
                font-size:24px;
            }
            &:hover {
                color: var(--primary-color3);
            }
        }
        .group-2 {
            margin-right: -40px;
            @include flexitem;
            flex-wrap: wrap;
        }
        .tf-button.style-2 {
            border-color:#888B8E ;
            width: calc(50% - 40px);
            margin-right: 40px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding: 14px 0;
            i {
                font-size:20px;
            }
            &:hover {
                border-color:#2196e7 ;
            }
        }
    }
}

.collection {
    .group-btn-nav {
        position: absolute;
        left: 49.5%;
        bottom: -22px;
        transform: translateX(-50%);
        max-width: 180px;
        display: flex;
        z-index: 10;
        gap: 23px;
        .button-collection-prev {
            left: -52px;
            &::after {
                background-color: var(--primary-color15);
                color: var(--primary-color3);
                border:1px solid var(--primary-color12) ;
            }
            &:hover {
                &::after {
                    background-color: var(--primary-color5);
                    color:#141B22 ;
                    border-color: var(--primary-color5);
                }
                
            }
        }
    }
}
/* Countdown
--------------------------------------------------*/

.countdown {
    .countdown__item {
        display: inline-flex;
        flex-direction: column;
        margin-right: 40px;
        &:last-child {
            margin-right: 0;
        }
        .countdown__value {
            @include flex(center,center);
            width: 108px;
            height: 108px;
            background: var(--primary-color25);
            color: var(--primary-color14);
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 56px;
            line-height: 68px;
            margin-bottom: 8px;
        }
        .countdown__label {
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            color: var(--primary-color2);
        }
        .countdown__item:last-child {
            .countdown__label {
                padding: 0;
            }
            
        }
    }
    &.style-2 {
        .countdown__item {
            margin-right: 28px;
            padding-right: 28px;
            position: relative;
            &::after {
                content: ":";
                right: -6px;
                position: absolute;
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 36px;
                line-height: 44px;
                color: #C2C3C5;
                
            }
            &:last-child {
                margin-right: 0px;
                padding-right: 0px;
                &::after {
                    display: none;
                }
            }
        }
        .countdown__value {
            width: unset;
            height: unset;
            font-size: 44px;
            line-height: 56px;
            background: none;
            color: #fff;
            margin-bottom: 0; 
        }

        .countdown__label {
            color: #F6F6F6;
        }
    }
}

/* Comming Soon
--------------------------------------------------*/
.comming-soon {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-color: var(--primary-color8);
    .bg-comming-soon {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
    .comming-soon-inner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
        text-align: center;
        .logo {
            margin-bottom: 40px;
        }
        .title {
            letter-spacing: 0.625em;
            font-size: 56px;
            line-height: 68px;
            color: var(--primary-color3);
            margin-bottom: 24px;

            @include mobile {
                letter-spacing: 0;
            }
        }
        .countdown {
            margin-bottom: 60px;
        }
        form {
            max-width: 615px;
            margin: 0 auto;
            margin-bottom: 73px;
            position: relative;
            input {
                padding: 16px 16px 15px;
                background-color: var(--primary-color26);
                color: var(--primary-color2);
            }
            .tf-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 100%;
                padding: 0 44px;
                &::before {
                    background-color: #5865F2;
                }
                &:hover {
                    border-color: #5865F2;
                }
            }
        }
        .group-btn {
            margin-bottom: 30px;
            .tf-button {
                padding: 12px 24px;
                margin-right: 18px;
                display: inline-flex;
                align-items: center;
                i {
                    font-size: 18px;
                }
                &:hover {
                    color: var(--primary-color3);
                }
                &.discord {
                    background-color: #5865F2;
                    color: #fff;
                    &:hover {
                        &::before {
                            background-color: #2196e7;
                        }
                    }
                }
                
            }
        }
        .social-item {
            @include flex(center,center);
            li {
                width: 60px;
                height: 60px;
                @include flex(center,center);
                background-color: var(--primary-color26);
                margin-right: 24px;
                @include transition3;
                cursor: pointer;
                &:last-child {
                    margin-right: 0;
                }
                a {
                    color: var(--primary-color13);
                    font-size: 27px;
                }
                &:hover {
                    background-color: #2196e7;
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }
    &.error-page {
        .comming-soon-inner {
            .image {
                margin-bottom: 11px;
                svg {
                    path {
                        stroke:var(--primary-color13);
                    }
                }
            }
            .title {
                font-size: 120px;
                line-height: 150%;
                letter-spacing: 0;
                margin-bottom: -10px;

                @include mobile {
                    font-size: 80px;
                    line-height: 1.2;
                }
            }
            .sub {
                margin-bottom: 43px;
                color: var(--primary-color14);
            }
            .tf-button {
                padding: 12px 106px;
                margin-right: 0;

                @include mobile {
                    padding: 12px 30px;
                }
            }
        }
    }
}




/* animation
--------------------------------------------------*/

.ani1 {
    -webkit-animation: ani1 10s infinite linear;
    animation: ani1 10s infinite linear;
}

.ani2 {
    -webkit-animation: ani2 10s infinite linear;
    animation: ani2 10s infinite linear;
}

.ani3 {
    -webkit-animation: ani3 4s infinite linear;
    animation: ani3 4s infinite linear;
}

.ani4 {
    -webkit-animation: ani4 7s infinite linear;
    animation: ani4 7s infinite linear;
}

.ani5 {
    -webkit-animation: ani5 7s infinite linear;
    animation: ani5 7s infinite linear;
}

@keyframes ani1 {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(-73px, 1px) rotate(-36deg);
        transform: translate(-73px, 1px) rotate(-36deg);
    }
    40% {
        -webkit-transform: translate(-141px, -72px) rotate(-72deg);
        transform: translate(-141px, -72px) rotate(-72deg);
    }
    60% {
        -webkit-transform: translate(-83px, -122px) rotate(-108deg);
        transform: translate(-83px, -122px) rotate(-108deg);
    }
    80% {
        -webkit-transform: translate(40px, -72px) rotate(-144deg);
        transform: translate(40px, -72px) rotate(-144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ani2 {
    0% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
    20% {
        -webkit-transform: translate(20px, 1px) rotate(36deg);
        transform: translate(20px, 1px) rotate(36deg);
    }
    40% {
        -webkit-transform: translate(100px, 72px) rotate(72deg);
        transform: translate(100px, 72px) rotate(72deg);
    }
    60% {
        -webkit-transform: translate(83px, 122px) rotate(108deg);
        transform: translate(83px,122px) rotate(108deg);
    }
    80% {
        -webkit-transform: translate(40px, 72px) rotate(144deg);
        transform: translate(40px, 72px) rotate(144deg);
    }
    100% {
        -webkit-transform: translate(0px, 0px) rotate(0deg);
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ani3 {
    0% {
        transform: scale(0.8, 0.8);
      }
      50% {
        transform: scale(1, 1);
      }
      100% {
        transform: scale(0.8, 0.8);
      }
}

@keyframes ani4 {
    0%, 100% {
        transform: translateX(0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    50% {
        transform: translateX(-20px);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}

@keyframes ani5 {
    0%, 100% {
        transform: translateX(0);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
    50% {
        transform: translateX(20px);
        -webkit-transition: all 0.3s ease;
        -moz-transition: all 0.3s ease;
        -ms-transition: all 0.3s ease;
        -o-transition: all 0.3s ease;
        transition: all 0.3s ease;
    }
}






/*Team Detail -----------------------
-----------------------------------*/
.team-details {
    margin-right: 30px;

    @include mobile {
        margin-right: 0;
    }
}

.content-detail {
    margin-left: 28px;
    position: relative;
    @include tablet {
        margin-left: 0;
    }
    .position {
        font-weight: 600;
        font-size: 20px;
        line-height: 120%;
        letter-spacing: 0.1em;
        color: var(--primary-color5);
        margin-bottom: 9px;
    }
    .name {
        font-size: 62px;
        line-height: 120%;
        color: var(--primary-color3);
        margin-bottom: 32px;
    }
    .title {
        color: var(--primary-color3);
        margin-bottom: 7px;
        &.mb14 {
            margin-bottom: 14px;
        }
    }
    .description {
        margin-right: 2px;
        margin-bottom: 25px;
    }
    .contact-infor {
        @include flex(center,left);
        flex-wrap: wrap;
        margin-left: -30px;
        margin-bottom: 40px;
        .contact-infor-box {
            @include flex(center,left);
            width: calc(50% - 30px);
            margin-left: 30px;
            .icon {
                margin-right: 11px;
            }
            &:last-child {
                margin-left: 48px;
                margin-right: -18px;
            }

            @include desktop-1200 {
                width: 100%;
                margin-left: 30px !important;
            }
        }

        
    }
    .social-item {
        @include flex(center,left);
      
        li {
            width: 52px;
            height: 52px;
            @include flex(center,center);
            background-color: var(--primary-color26);
            margin-right: 18px;
            a {
                color: var(--primary-color13);
                font-size: 20px;
            }
            &:hover {
                cursor: pointer;
                background-color: #2196e7;
                a {
                    color: #fff;
                }
            }
        }
    }
    .group-btn {
        max-width: 113px;
        position: absolute;
        bottom: 0;
        left: 45px;
    }
}

/* Testimonial
------------------------------------*/
.tf-testimonial {
    background: var(--primary-color8);
    padding: 54px 30px 40px 32px;
    margin-bottom: 28px;
    & .icon {
        margin-bottom: 27px;
        margin-left: 3px;
    }
    & p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color);
        margin-bottom: 33px;
    }
    & .author-tes {
        border-top: 2px solid var(--primary-color29);
        padding-top: 24px;
        display: flex;
        align-items: center;
        & .avt {
            width: 70px;
            height: 70px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 20px;
            flex-shrink: 0;
            & img {
                width: 100%;
            }
        }
        & .content {
            & a {
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 24px;
                text-transform: capitalize;
            }
            & h6 {
                font-family: 'Poppins';
                margin-top: 3px;
                font-weight: 400;
                font-size: 18px;
                color: #888B8E;
            }
        }
    }
}

/* Step
------------------------------------*/

.tf-container2 {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 1200px;
    max-width: 100%;
}
.container-fluid {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
    max-width: 100%;
}

/* Road Map
------------------------------------*/


/* Sign in
------------------------------------*/

.bg-sign-in {
    background: var(--product-color24);
}

.vertical-carousel {
    position: relative;
    margin-left: -162px;
    overflow: hidden;
    &::after,
    &::before {
        position: absolute;
        top: -10px;
        left: 0;
        right: 0;
        background: var(--product-color22);
        filter: blur(5px);
        transform: rotate(180deg);
        content: "";
        width: 100%;
        height: 1200px;
        opacity: 0.9;
        z-index: 4;
        @include transition5;
        visibility: visible;
    }
    &::before {
        top: unset;
        bottom: -10px;
        transform: unset;
    }
    &:hover::after,
    &:hover::before {
        opacity: 0;
        visibility: hidden;
    }
    & .content-slide {
        display: flex;
        margin-left: -30px;
        height: 1200px;
        overflow: hidden;
        & .swiper-h {
            margin: 0 15px;
            & .swiper-slide {
                height: auto !important;
            }
            & .sl-h {
                height: 982px;
            }
        }

        @include desktop-1300 {
            height: 1000px;
        }
        @include mobile {
            display: none;
        }
    }
}

.sign-in-form {
    width: 610px;
    text-align: center;
    margin-top: 17.8%;
    margin-left: 8.7%;

    @include desktop-1450 {
        width: 100%;
        margin-right: 15px;
        margin-left: 0;
    }
    @include mobile {
        margin-top: 60px;
    }
    &.style2 {
        margin-top: 13.7%;
        & .submit {
            margin-top: 10px;
            margin-bottom: 28px;
            &:hover {
                color: var(--primary-color3);
            }
        }
        & .or {
            &::before {
                width: 100px;
            }
        }
    }
    & h2 {
        font-family: 'Bakbak One';
        font-weight: 400;
        font-size: 36px;
        text-transform: uppercase;
        color: var(--primary-color3);
        margin-bottom: 5px;

    }
    & p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color);
        margin-bottom: 30px;
    }
    & form {
        & input {
            margin-bottom: 21px;
            padding-top: 15px;
        }
        & .forgot-pass-wrap {
            display: flex;
            justify-content: space-between;
            margin-bottom: 18px;
            & label {
                position: relative;
                padding-left: 30px;
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 14px;
                color: var(--primary-color);
                & input {
                    position: absolute;
                    opacity: 0;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0;
                    z-index: 1;
                    margin-right: 0px;
                    margin-bottom: 0px;
                    &:checked ~ .btn-checkbox:after {
                        display: block;
                    }
                }
                & .btn-checkbox {
                    position: absolute;
                    top: 4px;
                    left: 0;
                    width: 22px;
                    height: 22px;
                    background-color: transparent;
                    border: 2px solid var(--primary-color34);
                    &::after {
                        content: "\f00c";
                        font-family: "Font Awesome 5 Pro";
                        position: absolute;
                        font-size: 16px;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        color: var(--primary-color5);
                        display: none;
                    }
                }
            }
            & a {
                font-family: 'Poppins';
                font-weight: 400;
                font-size: 14px;
                color: #2196e7;
            }
        }
        & .submit {
            width: 100%;
            padding: 13px 10px 11px 10px;
            margin-bottom: 21px;
        }
        & .choose-sign {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            color: #888B8E;
            margin-bottom: 27px;
            & a {
                color: var(--primary-color14);
                &:hover {
                    color: #2196e7;
                }
            }
        }
       
    }
    & .or {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 16px;
        color: var(--primary-color);
        display: block;
        position: relative;
        margin-bottom: 14px;
        & span {
            position: relative;
            z-index: 5;
        }
        &::after {
            content: '';
            width: 100%;
            height: 1px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: var(--product-color23);
            z-index: 0;
        }
        &::before {
            content: '';
            width: 40px;
            height: 30px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: var(--product-color24);
            z-index: 2;
        }
    }
    & .box-sign-social {
        display: flex;
        & a {
            width: 100%;
            background: var(--product-color25);
            padding: 13px 10px 13px 10px;
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 18px;
            color: var(--primary-color2);
            &::before {
                background-color: #2196e7;
            }
            &:hover {
                color: var(--primary-color3);
            }
            & i {
                margin-right: 7px;
                font-size: 25px;
                vertical-align: middle;
                margin-top: -3px;
            }
            &:first-child {
                margin-right: 15px;
            }
        }
    }
}

/* Home 03
------------------------------------*/
.title-ss {
    text-align: center;
    margin-bottom: 60px;
    position: relative;
    z-index: 5;
    & h3 {
        font-family: 'Bakbak One';
        font-weight: 400;
        font-size: 44px;
        color: var(--primary-color3);
        & span {
            color: #2196e7;
        }
    }
    & p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: var(--primary-color);
        padding: 0 11%;

        @include mobile {
            padding: 0;
        }
    }
}
.tf-about-ss {
    padding-bottom: 95px;
    position: relative;
    .icon {
        position: absolute;
        top: 34%;
        left: 0%;
    }
    & .title-ss {
        margin-bottom: 60px;
        & h3 {
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 44px;
            color: var(--primary-color3);
            margin-bottom: 22px;
        }
        & p {
            font-family: 'Poppins';
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
            color: var(--primary-color);
            padding: 0 11%;
            @include mobile {
                padding: 0;
            }
        }
    }
    & .btn {
        display: block;
        text-align: center;
        margin-top: -6px;
        & a {
            padding: 10px 23px 10px 23px;
        }
    }
}

.tf-work-ss {
    & .title-ss {
        margin-bottom: 67px;
        & h3 {
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 44px;
            color: var(--primary-color3);
            & span {
                color: #2196e7;
            }
        }
    }

}

.tf-roadmap-ss {
    padding: 66px 0;
    & .title-ss {
        margin-bottom: 66px;
    }
}

.tf-collection-ss {
    padding-top: 62px;
    padding-bottom: 74px;
}

.tf-team-ss {
    padding: 41px 0;
    position: relative;
    .icon {
        position: absolute;
        top: 39%;
        left: 0%;
    }
}

.tf-partner-ss {
    padding: 26px 0;
    position: relative;
    .icon {
        position: absolute;
        bottom: 3%;
        left: 0%;
    }
}

.tf-faq-ss {
    padding: 49px 0;
    & .title-ss {
        margin-bottom: 66px;
    }
}