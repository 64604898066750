@use '../../scss/' as *;


.tf-slider {
    background: url('../../assets/images/slider/bg-slider.png') center center no-repeat;
    background-size: cover;
    position: relative;

    @include mobile {
       
    }
    &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #040B11;
        z-index: -1;
    }

    &.home2 {
        background: url('../../assets/images/slider/bg-slider.png') center center no-repeat;
        background-size: cover;
    }
    &.home3 {
        background: url('../../assets/images/slider/bg-slider.png') center center no-repeat;
        background-size: cover;
    }
}
.tf-slider-item {
    @include flexitem;
    padding: 97px 3% 143px 12.5%;

    @include desktop-1650 {
        padding: 97px 0 143px 0;
    }


    .content-inner {
        width: 50%;
        position: relative;
        padding-top: 72px;
        max-width: 560px;
        margin-right: 65px;
        .text-typing {
            display: flex;
            align-items: center;
        }
        .heading {
            color:  #fff;     
            margin-bottom: 19px;

            &.mb0 {
                margin-bottom: 0;
            }
        }
        .animate-contain {
            height: 80px;
            overflow: hidden;
            text-align: center;
        
            margin-left: 20px;
        }
        .animated-text {
            display: inline-block;
            transition: 300ms all;
            margin-top: 0;
            animation: slide 5s infinite alternate ease-in-out;
            color: var(--primary-color5);
            height: 100%;
        }
        .animated-text span {
            display: block;
            font-size: 72px;
            font-weight: 400;
            height: 100%;
            display: flex;
            align-items: center;
            font-family: "Bakbak One", sans-serif;
            text-transform: uppercase;

            @include mobile {
                font-size: 50px;
                line-height: 60px;
            }

        }

        @keyframes slide{
            0%{margin-top:0;}
            20%{margin-top:0;}
            40%{margin-top:-80px;}
            60%{margin-top:-80px;}
            80%{margin-top:-160px;}
            100%{margin-top:-160px;}
        
        }
        .sub-heading {
            color: #fff;
            font-size: 20px;
            line-height: 30px;
            margin-bottom: 48px;
        }
        .tf-button {
            padding: 11px 24px;
            margin-right: 20px;


            @include w-402 {
                width: 70%;
                margin-right: 0;
            }
            &.style-2 {
                padding: 11px 22px;
                color: #fff;
            }
        }
    }
    .image {
        flex-grow: 1;
        width: 50%;
        display: flex;
        img {
            margin-right: 18px;
            border-radius: 12px;
            width: 33.33%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
    .content-right {
        .content-slide {
            @include flexitem;
            margin-left: 0;
            height: 532px;
            overflow: hidden;
            .mr25 {
                margin-right: 25px;
            } 
            
            .image {
                
                width: 352px;
                border-radius: 12px;
                overflow: hidden;
                margin-left: 25px;
                position: relative;
	            overflow: hidden;

                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                &.s1 {
                    width: 266px;
                }

                @keyframes fadeIn {
                    0% { opacity: 0; }
                    20% { opacity: 0; }
                    60% { opacity: 1; }
                    100% { opacity: 1; }
                }
                
                .fadeInClass {
                    animation-name: fadeIn;
                    animation-duration: 7s;
                    animation-iteration-count: infinite;
                    animation-direction: alternate;
                    animation-timing-function: linear;
                }

                @include desktop-1650 {
                    width: 100%;
                }
            }
        }
    }
    &.style-2 {
        padding: 493px 0 103px;
        text-align: center;
        .content-inner {
            width: 100%;
            max-width: unset;
            margin-right: 0;
            padding-top: 0;
        }
        .tf-button {
            &.style-2 {
                margin-right: 0;
            }
        }
        
    }

    &.style-3 {
        padding-top: 10px;
        padding-bottom: 138px;
        .content-inner {
            padding-top: 131px;
            margin-right: 163px;
            .sub-heading {
                margin-bottom: 25px;
            }
            .counter-wrap {
                margin-bottom: 47px;
                @include flexitem;
                .tf-counter {
                    margin-right: 75px;
                    .content {
                        font-size: 44px;
                        line-height: 56px;
                        color: #fff;
                    }
                    h6 {
                        font-family: 'Poppins';
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 26px;
                        color: #C2C3C5;
                    }
                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
        .image {
            display: unset;
            position: relative;
            img {
                margin-right: 0;
                width: unset;
            }
            .img {
                position: relative;
                z-index: 2;
            }
            .img-1,.img-2 {
                position: absolute;
                top: 54%;
                left: 70%;
                z-index: 1;
            }
            .img-2 {
                left: 4%;
                top: 16%;
            }
        }
    }

    
    @include desktop-1200 {
        flex-wrap: wrap;
        .content-inner {
            width: 100%;
            max-width: 100%;
            margin-bottom: 50px;
        }
        .content-right  {
            width: 100%;

            .content-slide {
                .image {
                    &:first-child {
                        margin-left: 0;
                    }
                }

                @include tablet {
                    height: 330px;
                }
            }
        }
    }

}

.tf-slider {
    position: relative;
    .swiper-slide {
        .heading,.sub-heading,.btn-slider,.counter-wrap , .animate-contain {
            opacity: 0;
            transform: translateY(200px);
        }
        .sub-heading {
            transform: translateY(300px);
        }
    }
    .swiper-slide-active {
        .heading,.sub-heading ,.btn-slider,.counter-wrap , .animate-contain {
            opacity: 1;
            transform: translateY(0);
            transition-delay: 300ms;
            visibility: visible;
            -webkit-transition: transform 1000ms ease, opacity 1000ms ease;
            -moz-transition: transform 1000ms ease, opacity 1000ms ease;
            -ms-transition: transform 1000ms ease, opacity 1000ms ease;
            -o-transition: transform 1000ms ease, opacity 1000ms ease;
            transition: transform 1000ms ease, opacity 1000ms ease;
        }
        .sub-heading {
            transition-delay: 700ms;
        }
        .counter-wrap {
            transition-delay: 850ms;
        }
        .btn-slider {
            transition-delay: 1000ms;
        }
    } 
}

