@use '../../scss/' as *;

.tf-step {
    border-radius: 10px;
    background: whitesmoke;
    padding: 76px 30px 30px 30px;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0px 10px 25px rgba(71, 92, 133, 0.1);
    position: relative;
    @include transition3;
    & .step-title {
        position: relative;
        margin-bottom: 18px;
        & .sub-number {
            position: absolute;
            top: -50px;
            left: 10%;
            transform: translateX(-50%);
            font-family: "Bakbak One";
            font-weight: 400;
            font-size: 80px;
            color: #001cdf;
            opacity: 0.3;
            @include transition3;
        }
        & h3 {
            position: relative;
            font-family: 'Bakbak One';
            font-weight: 400;
            font-size: 24px;
            text-transform: uppercase;
            color: rgba(39, 39, 39, 0.98);
        }
    }
    & p {
        font-family: 'Poppins';
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: rgba(39, 39, 39, 0.98);
    }
    &.style2 {
        text-align: left;
        & .step-title {
            position: relative;
            margin-bottom: 18px;
            & .sub-number {
                position: absolute;
                top: -13px;
                left: 40px;
                font-family: "Bakbak One";
                font-weight: 400;
                font-size: 80px;
                opacity: 0.3;
                color: transparent;
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: #2196e7;
                @include transition3;
            }
            & h3 {
                position: relative;
                font-family: 'Bakbak One';
                font-weight: 400;
                font-size: 24px;
                text-transform: uppercase;
                color: var(--primary-color3);
            }
        }
    }
    &:hover {
        box-shadow: 0px 10px 25px rgba(71, 92, 133, 0.1);
        cursor: pointer;
        .step-title {
            .sub-number {
                color: #2196e7;
                opacity: 0.8;
            }
        }
    }
    &::before {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        top: 0px;
        left: 0px;
        border-left: 5px solid #2196e7;
        border-top: 5px solid #2196e7;
    }
    &::after {
        opacity: 0;
        @include transition5;
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        bottom: 0px;
        right: 0px;
        border-right: 5px solid #2196e7;
        border-bottom: 5px solid #2196e7;
    }
    &.active,
    &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 15px var(--primary-color36);
        &::before,&::after {
            opacity: 1;
            width: 50px;
            height: 50px;
        }
    }
}
.is_dark .tf-step {
    box-shadow: none;
    &.active,
    &:hover {
        cursor: pointer;
        box-shadow: 0px 5px 15px var(--primary-color36);
        &::before,&::after {
            opacity: 1;
        }
    }
}